import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

axios.defaults.withCredentials = true;

const ConfirmationPage = () => {
  const { confirmationCode } = useParams(); // Get confirmationCode from the URL parameters
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (confirmationCode) {
      const url = `https://api.clickatown.com/api/users/confirm/${confirmationCode}`;
      console.log("URL =>", url);
      
      // Make an async request to confirm the account
      const confirmAccount = async () => {
        try {
          const response = await axios.get(url, { 
            withCredentials: true 
        });
          console.log("Response =>", response);
          setMessage("Account confermato correttamente!");
        } catch (error) {
          console.error("Error confirming account =>", error);
          setMessage("Errore nella conferma dell'account.");
        }
      };

      confirmAccount();
    }
  }, [confirmationCode]); // Run this effect whenever confirmationCode changes

  return (
    <div>
      <header>
        <h3>
          <strong>{message || "Confermando l'account..."}</strong>
        </h3>
      </header>
    </div>
  );
};

export default ConfirmationPage;