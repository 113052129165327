import React, { useState } from "react";
import logo from "../assets/logo.svg";
import "../assets/_reset-form.css";
import * as CryptoJS from "crypto-js";
import axios from "axios";
import { useParams } from "react-router-dom";

axios.defaults.withCredentials = true;

const resetPassword = (payload) => {
  payload = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    process.env.REACT_APP_SECRET_KET
  ).toString();
  return axios.post("https://api.clickatown.com/api/users/reset", payload);
};

const PasswordResetPage = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { forgotPasswordCode } = useParams();
  console.log("forgot code => ", forgotPasswordCode);
  const [pwdMatchError, setPwdMatchError] = useState(false);
  const [pwdMatchErrorMsg, setPwdMatchErrorMsg] = useState("");
  const [reset, setReset] = useState(false);

  return (
    <div className="reset">
      <div className="reset__container">
        <img src={logo} alt="CaT Logo" className="reset__logo" />
        {reset ? (
          <text style={{ alignSelf: "center", fontSize: "18px" }}>
            <b>Modifica completata con successo!</b>
            <br></br>
            <br></br>
            Torna all'applicazione per eseguire l'accesso.
          </text>
        ) : (
          <>
            <input
              type="text"
              className="reset__textBox"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <input
              type="password"
              className={
                !pwdMatchError
                  ? "register__textBox"
                  : "register__textBox Rerror"
              }
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Conferma Password"
            />
            {pwdMatchError ? (
              <p className="register__error">{pwdMatchErrorMsg}</p>
            ) : null}
            <button
              className="reset__btn"
              onClick={() =>
                resetPassword({
                  password: password,
                  password_repeat: confirmPassword,
                  forgotPasswordCode: forgotPasswordCode,
                }).then((response) => {
                  if (!response.data.error) {
                    setReset(true);
                  } else {
                    let errorMsg = response.data.msg;
                    setPwdMatchError(true);
                    setPwdMatchErrorMsg(errorMsg);
                  }
                })
              }
            >
              Resetta la password
            </button>
            <div>Non hai un account? Registrati ora dall'applicazione.</div>
          </>
        )}
      </div>
    </div>
  );
};
export default PasswordResetPage;
