import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConfirmationPage from './pages/ConfirmationPage';
import PasswordResetPage from './pages/PasswordResetPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/api/confirm/:confirmationCode" element={<ConfirmationPage />} />
                <Route path="/api/reset/:forgotPasswordCode" element={<PasswordResetPage />} />
            </Routes>
        </Router>
    );
}

export default App;